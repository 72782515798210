//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShow: this.value,
      showUp: false
    }
  },
  computed: {
    ...mapGetters('notification', ['getUnread'])
  },
  watch: {
    value() {
      this.toggle()
    },
    isShow(val) {
      this.$emit('input', val)
    },
    '$route.path'() {
      this.$emit('input', false)
    }
  },
  methods: {
    onClickNav(ev, navigate, clickedMenu) {
      this.$emit('menu-click', clickedMenu)
      navigate(ev)
      if (clickedMenu.name === 'Woi!') {
        const sne = new Event('showMobileNotification')
        document.dispatchEvent(sne)
      }
      if (clickedMenu.name === 'Verify') {
        // dataLayer.push({
        //   event: 'free_user_upgrading'
        // })
        // this.$router.push('/setting/billing?isPricePlanShown=true')
        if (this.$auth.user.currentBusiness.business_type === 'individual') {
          this.$router.push('/setting/profile?isVerificationModalShown=true')
        } else {
          this.$router.push('/setting/business?isVerificationModalShown=true')
        }
      }
    },
    toggle() {
      if (this.isShow) {
        this.showUp = false
        setTimeout(() => {
          this.isShow = false
        }, 150)
      } else {
        this.showUp = false
        this.isShow = true
        this.$nextTick(() => (this.showUp = true))
      }
    }
  }
}
