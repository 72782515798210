var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isShow)?_c('div',{staticClass:"fixed left-0 top-0 z-[102] h-full w-full overflow-x-hidden overflow-y-hidden",attrs:{"id":("modal-" + _vm._uid)}},[_c('div',{staticClass:"relative h-full",class:[
      _vm.fullOnMobile
        ? 'md:align-center md:flex md:items-center md:p-4'
        : 'align-center flex items-center p-4'
    ]},[_c('div',{staticClass:"absolute inset-0 backdrop-blur-sm backdrop-filter",attrs:{"aria-hidden":"true"},on:{"click":function($event){_vm.closeOnClick && _vm.onCancel()}}},[_c('div',{staticClass:"absolute inset-0 bg-gray-600 opacity-70"})]),_vm._v(" "),_c('div',{staticClass:"relative flex flex-col overflow-hidden text-left shadow-xl",class:[
        _vm.bg,
        _vm.width,
        !_vm.fullOnMobile
          ? 'mx-auto max-h-full transform justify-between rounded'
          : 'mx-0 max-h-full justify-self-auto rounded-none md:mx-auto md:justify-between md:rounded'
      ],attrs:{"role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"}},[((_vm.$mq === 'sm' && !_vm.fullOnMobile) || _vm.showCloseButton)?_c('SclvIcon',{staticClass:"absolute right-[16px] mt-[20px] cursor-pointer text-[12px] text-red-900",class:{ hidden: _vm.isCancelMobileButton },attrs:{"icon":"ic-times","data-testid":"close-modal-btn"},on:{"click":function($event){return _vm.onCancelMobile()}}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.title || !!_vm.subtitle),expression:"!!title || !!subtitle"}],staticClass:"pr-9",class:[
          'px-[16px] pb-2 pt-[16px]',
          {
            'hidden md:block': _vm.fullOnMobile && !_vm.mobileOnlyTitle,
            'md:hidden': _vm.mobileOnlyTitle
          }
        ]},[(_vm.title)?_c('h1',{staticClass:"text-[16px] font-bold leading-[21px] text-black-900",class:[{ 'pr-[16px]': _vm.disableContentOverflow }, _vm.titleClass]},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]):_vm._e(),_vm._v(" "),_vm._t("subtitle",function(){return [(_vm.subtitle)?_c('h2',{staticClass:"mt-[6px] whitespace-pre-line text-[12px] leading-[14px] text-black-200",class:{ 'pr-[16px]': _vm.disableContentOverflow },domProps:{"textContent":_vm._s(_vm.subtitle)}}):_vm._e()]})],2),_vm._v(" "),(_vm.fullOnMobile)?_c('SimpleHeader',{staticClass:"z-[100] md:hidden",attrs:{"px":"px-[22px]","page-title-mobile":_vm.title,"page-subtitle":_vm.subtitle},scopedSlots:_vm._u([{key:"back",fn:function(){return [_vm._t("mobile-back",function(){return [_c('span',{staticClass:"cursor-pointer text-[12px] font-medium text-primary",on:{"click":_vm.onCancel}},[_vm._v("\n              "+_vm._s(_vm.labelCancel)+"\n            ")])]})]},proxy:true},{key:"action-header",fn:function(){return [_vm._t("mobile-action-header",function(){return [_c('span',{staticClass:"text-[12px] font-medium",class:[_vm.mobileOkDisabled ? 'text-black-200' : 'text-primary'],on:{"click":_vm.onOk}},[_vm._v("\n              "+_vm._s(_vm.labelOk)+"\n            ")])]})]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"leading-[18px]",class:[
          _vm.padding,
          { 'mt-[60px] h-screen md:mt-0 md:h-auto': _vm.fullOnMobile },
          { 'overflow-y-auto': !_vm.disableContentOverflow }
        ]},[_vm._t("default")],2),_vm._v(" "),(!_vm.noFooter)?_c('div',{staticClass:"flex justify-end space-x-[16px] overflow-visible",class:[
          _vm.padding,
          { 'hidden md:flex': _vm.fullOnMobile, 'border-2': _vm.hasFooterDivider }
        ]},[_vm._t("footer",function(){return [(_vm.$mq !== 'sm' && _vm.showCloseButton)?_c('SclvButton',{staticClass:"cursor-pointer",attrs:{"small":"","color":"text-red-900 bg-black-50"},on:{"click":_vm.onCancel}},[_vm._v("\n            "+_vm._s(_vm.labelCancel)+"\n          ")]):_vm._e(),_vm._v(" "),_c('SclvButton',{staticClass:"shadow shadow-lg",attrs:{"color":"bg-primary text-white","small":""},on:{"click":_vm.onOk}},[_vm._v("\n            "+_vm._s(_vm.labelOk)+"\n          ")])]})],2):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }