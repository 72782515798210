//
//
//
//

export default {
  name: 'Default',
  // watch: {
  //   $route(to, from) {
  //     const refreshToken = localStorage.getItem('auth._refresh.customLocal')
  //     if (!refreshToken || refreshToken === 'false') {
  //       location.reload()
  //     }
  //   }
  // },
  head() {
    return {
      script: [
        {
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${this.$config.scalevGtm}');`,
          type: 'text/javascript'
        }
      ]
    }
  },
  mounted() {
    // window.addEventListener('storage', (event) => {
    //   if (event.storageArea !== localStorage) return
    //   if (event.key === 'auth._refresh.customLocal') {
    //     location.reload()
    //   }
    // })
  }
}
