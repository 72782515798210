import {
  mapMutationsHelper,
  mapGettersHelper,
  encodeQueryData,
  decodeQueryData,
  initQueryParams
} from '@/utils/helpers.js'

export const state = () => ({
  metaBusiness: {},
  metaOwnedBusiness: {},
  currentOwner: null,
  business: [],
  ownedBusiness: [],
  allBusinessCount: 0,
  ownedBusinessCount: 0,
  accountHolder: null,
  username: null,
  phone: null,
  verificationData: null
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  resetBusiness({ commit }) {
    commit('SET_BUSINESS', [])
    commit('SET_META_BUSINESS', {})
  },
  // async fetchAllBak({ commit }, query) {
  //   if (isEmpty(query)) {
  //     query = {
  //       page: 1,
  //     }
  //   }
  //   query = {
  //     isActive: 1,
  //     page: 1,
  //     ...query,
  //   }
  //   const queries = encodeQueryData(query)
  //   const url = `business/?${queries}`
  //   try {
  //     const resBusinessList = await this.$axios.$get(url)
  //     commit('SET_BUSINESS', resBusinessList.data.results)
  //     let meta = decodeQueryData(resBusinessList.data.next?.split('?')?.[1])
  //     if (!resBusinessList.data.next)
  //       meta = decodeQueryData(resBusinessList.data.previous?.split('?')?.[1])
  //     const page = resBusinessList.data.next ? meta.page - 1 : meta.page - 0 + 1
  //     commit('SET_META_BUSINESS', {
  //       itemsLength:
  //         resBusinessList.data.count || resBusinessList.data.results.length,
  //       page,
  //       perPage: meta.page_size - 0,
  //     })
  //     return resBusinessList
  //   } catch (error) {
  //     if (error.response?.status === 401) {
  //       throw new Error('Bad Credentials')
  //     } else if (error.response?.status === 502) {
  //       throw new Error('Network Error')
  //     }
  //     throw error
  //   }
  // },
  async fetchAll({ commit, state }, query) {
    const { page, pageSize, itemsLength } = state.metaBusiness
    if (itemsLength === state.business?.length) {
      return state.business
    }
    const initQuery = initQueryParams({
      page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/business-user/me/all/?${queries}`
    try {
      const resBusinessList = await this.$axios.$get(url)
      const fixBusinessList = resBusinessList.data.results.map((bus) => ({
        idMember: bus.id,
        invitationStatus: bus.is_verified ? 'accepted' : 'invited',
        businessPhone: bus.phone,
        isUserBusinessActive: !bus.is_locked,
        ...bus.business
      }))
      const allBusiness = [...state.business, ...fixBusinessList]
      commit('SET_BUSINESS', allBusiness)
      const businessCount = resBusinessList.data?.count ?? 0
      commit('SET_ALL_BUSINESS_COUNT', businessCount)
      const newMeta = decodeQueryData(
        resBusinessList.data.next?.split('?')?.[1]
      )
      if (newMeta) {
        commit('SET_META_BUSINESS', {
          itemsLength:
            resBusinessList.data.count || resBusinessList.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return allBusiness
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetchOwned({ commit, state }, query) {
    const { page, pageSize, itemsLength } = state.metaOwnedBusiness
    if (itemsLength === state.business?.length) {
      return state.business
    }
    const initQuery = initQueryParams({
      page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/business-user/me/all/?${queries}`
    try {
      const resBusinessList = await this.$axios.$get(url)
      const fixBusinessList = resBusinessList.data.results.map((bus) => ({
        idMember: bus.id,
        invitationStatus: bus.is_verified ? 'accepted' : 'invited',
        businessPhone: bus.phone,
        isUserBusinessActive: !bus.is_locked,
        ...bus.business
      }))
      const ownedBusiness = [...state.business, ...fixBusinessList]
      commit('SET_OWNED_BUSINESS', ownedBusiness)
      const businessCount = resBusinessList.data?.count ?? 0
      commit('SET_OWNED_BUSINESS_COUNT', businessCount)
      const newMeta = decodeQueryData(
        resBusinessList.data.next?.split('?')?.[1]
      )
      if (newMeta) {
        commit('SET_META_OWNED_BUSINESS', {
          itemsLength:
            resBusinessList.data.count || resBusinessList.data.results.length,
          page: newMeta.page - 0,
          pageSize: newMeta.page_size - 0
        })
      }
      return ownedBusiness
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async fetch({ commit }, idBusiness) {
    const url = `/business/${idBusiness}/`
    try {
      const resDetailBusiness = await this.$axios.$get(url)
      return resDetailBusiness.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  setBusiness({ commit }, payload) {
    commit('SET_BUSINESS', payload)
  },
  async addBusiness(store, payload) {
    try {
      const {
        accountHolder,
        isTax,
        taxRate,
        username,
        phone,
        address,
        location
      } = payload
      const resEditAccount = await this.$axios.$post('/business/', {
        account_holder: accountHolder,
        is_tax: isTax,
        tax_rate: taxRate,
        phone,
        username,
        address,
        location
      })
      return resEditAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async changeBusiness(store, idBusiness) {
    try {
      const resChangeBusiness = await this.$axios.$put(
        `/user/me/switch-business/`,
        {
          current_business: idBusiness
        }
      )
      return resChangeBusiness
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async changeOwnership({ rootState }, { idUserStaff, idRole, idBusiness }) {
    try {
      const resChangeOwnership = await this.$axios.$put(
        `/business/${idBusiness}/change-owner/`,
        { owner: idUserStaff }
      )
      return resChangeOwnership
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async editBusiness(store, { idBusiness, payload }) {
    try {
      const { accountHolder, isTax, taxRate, username, address, location } =
        payload
      const resEditAccount = await this.$axios.$patch(
        `/business/${idBusiness}/`,
        {
          account_holder: accountHolder,
          username,
          is_tax: isTax,
          tax_rate: taxRate,
          address,
          location
        }
      )
      return resEditAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async switchBusinessPhysical(store, { idBusiness, isPhysicalItemEnabled }) {
    try {
      const res = await this.$axios.$patch(`/business/${idBusiness}/`, {
        is_physical_item_enabled: isPhysicalItemEnabled
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async editBusinessTaxRate(_store, { idBusiness, payload }) {
    try {
      const { isTax, taxRate } = payload
      const resEditAccount = await this.$axios.$patch(
        `/business/${idBusiness}/`,
        {
          is_tax: isTax,
          tax_rate: taxRate
        }
      )
      return resEditAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async editBusinessOnboarding(_store, { idBusiness, isOnboardingCompleted }) {
    try {
      const resEditBusiness = await this.$axios.$patch(
        `/business/${idBusiness}/`,
        {
          is_onboarding_completed: isOnboardingCompleted
        }
      )
      return resEditBusiness
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async editBusinessAccount(_store, { ...payload }) {
    try {
      const {
        phone,
        isTelegramNotified,
        isTgPublicOrder,
        isTgPrivateOrder,
        isTgDraft,
        isTgPending,
        isTgPaid
      } = payload
      const resEditAccountBusiness = await this.$axios.$patch(
        `business-user/me/`,
        {
          business_phone: phone,
          is_telegram_notified: isTelegramNotified,
          is_tg_public_order: isTgPublicOrder,
          is_tg_private_order: isTgPrivateOrder,
          is_tg_draft: isTgDraft,
          is_tg_pending: isTgPending,
          is_tg_paid: isTgPaid
        }
      )
      return resEditAccountBusiness
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async requestUpdate(store, payload) {
    try {
      const {
        id,
        businessType,
        legalName,
        address,
        subdistrict,
        cityName,
        provinceName,
        country,
        idFile,
        npwpFile
      } = payload
      const resRequestUpdate = await this.$axios.$post(
        `/business/${id}/request-update/`,
        {
          business_type: businessType,
          legal_name: legalName,
          address,
          subdistrict_name: subdistrict,
          city_name: cityName,
          province_name: provinceName,
          country_name: country,
          id_file: idFile,
          npwp_file: npwpFile
        }
      )
      return resRequestUpdate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateRequestUpdate(store, payload) {
    try {
      const {
        id,
        businessType,
        legalName,
        address,
        subdistrict,
        cityName,
        provinceName,
        country,
        idFile,
        npwpFile
      } = payload
      const resRequestUpdate = await this.$axios.$put(
        `/update-request/${id}/`,
        {
          business_type: businessType,
          legal_name: legalName,
          address,
          subdistrict_name: subdistrict,
          city_name: cityName,
          province_name: provinceName,
          country_name: country,
          id_file: idFile,
          npwp_file: npwpFile
        }
      )
      return resRequestUpdate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async changeType(store, businessType) {
    try {
      const res = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/business/me/type/`,
        {
          business_type: businessType
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async verification({ commit }, payload) {
    const {
      nibId,
      taxId,
      legalName,
      address,
      subdistrictName,
      cityName,
      provinceName,
      countryName,
      postalCode,
      idFile,
      npwpFile
    } = payload
    try {
      const res = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/business/me/verification/`,
        {
          business_type: 'corporation',
          nib_id: nibId,
          tax_id: taxId,
          legal_name: legalName,
          address,
          subdistrict_name: subdistrictName,
          city_name: cityName,
          province_name: provinceName,
          country_name: countryName,
          postal_code: postalCode,
          id_file: idFile,
          npwp_file: npwpFile
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async verificationUpdate({ commit }, payload) {
    const {
      nibId,
      taxId,
      legalName,
      address,
      subdistrictName,
      cityName,
      provinceName,
      countryName,
      postalCode,
      idFile,
      npwpFile
    } = payload
    try {
      const res = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/business/me/legal/`,
        {
          business_type: 'corporation',
          nib_id: nibId,
          tax_id: taxId,
          legal_name: legalName,
          address,
          subdistrict_name: subdistrictName,
          city_name: cityName,
          province_name: provinceName,
          country_name: countryName,
          postal_code: postalCode,
          id_file: idFile,
          npwp_file: npwpFile
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete(store, { idBusiness, password }) {
    try {
      const resDeleteBusiness = await this.$axios.$delete(
        `business/${idBusiness}/`,
        {
          data: {
            password
          }
        }
      )
      return resDeleteBusiness.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async leave(store, { password }) {
    try {
      const resLeaveBusiness = await this.$axios.$delete(`business-user/me/`, {
        data: {
          password
        }
      })
      return resLeaveBusiness.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async acceptInvitation() {
    try {
      const resAcceptBusiness = await this.$axios.$put(
        `business-user/me/accept-direct/`
      )
      return resAcceptBusiness
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async denyInvitation() {
    try {
      const resAcceptBusiness = await this.$axios.$delete(
        `business-user/me/deny/`
      )
      return resAcceptBusiness
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async updateLogo(store, logoFile) {
    try {
      const formData = new FormData()
      formData.append('logo', logoFile)
      formData.append('type', 'file')
      const idBusiness = this.$auth.user.currentBusiness.id
      const resNewAvatar = await this.$axios.$put(
        `/business/${idBusiness}/upload-logo/`,
        formData
      )
      return resNewAvatar
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  setAccountHolder({ commit }, payload) {
    commit('SET_ACCOUNT_HOLDER', payload)
  },
  setUsername({ commit }, payload) {
    commit('SET_USERNAME', payload)
  },
  setPhone({ commit }, payload) {
    commit('SET_PHONE', payload)
  },
  async checkUsername(commit, username) {
    try {
      const resCheckUsername = await this.$axios.$get(
        `business-search/check-username/?username=${username}`
      )
      return resCheckUsername
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },
  async fetchVerificationById({ commit }, id) {
    try {
      const resVerifData = await this.$axios.$get(`/update-request/${id}/`)
      commit('SET_VERIFICATION_DATA', resVerifData.data)
      return resVerifData.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
