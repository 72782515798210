import {
  mapGettersHelper,
  mapMutationsHelper,
  initQueryParams,
  encodeQueryData
} from '@/utils/helpers.js'

export const state = () => ({
  transactionMeta: {
    pageSize: 25,
    lastId: undefined
  },
  transaction: [],
  currentBalance: 0,
  payoutTarget: null,
  affiliatedBusiness: [],
  affiliatedBusinessMeta: {
    pageSize: 25,
    lastId: undefined
  },
  newMe: null
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_TRANSACTION', [])
      commit('SET_TRANSACTION_META', {
        pageSize: 25,
        lastId: undefined
      })
    }
    const { pageSize, lastId } = state.transactionMeta
    const initQuery = initQueryParams({
      page_size: isFirst ? 25 : pageSize,
      last_id: isFirst ? undefined : lastId,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/affiliate-transaction/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const allTransaction = [...state.transaction, ...res.data.results]
      commit('SET_TRANSACTION', allTransaction)
      const nextMeta = {
        pageSize,
        lastId: res.data.results[res.data.results.length - 1]?.id || undefined
      }
      commit('SET_TRANSACTION_META', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllAffiliatedBusiness(
    { commit, state },
    { query, isFirst = false } = {}
  ) {
    if (isFirst) {
      commit('SET_AFFILIATED_BUSINESS', [])
      commit('SET_AFFILIATED_BUSINESS_META', {
        pageSize: 25,
        lastId: undefined
      })
    }
    const { pageSize, lastId } = state.affiliatedBusinessMeta
    const initQuery = initQueryParams({
      page_size: isFirst ? 25 : pageSize,
      last_id: isFirst ? undefined : lastId,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/affiliated-businesses/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const allAffiliatedBusiness = [
        ...state.affiliatedBusiness,
        ...res.data.results
      ]
      commit('SET_AFFILIATED_BUSINESS', allAffiliatedBusiness)
      const nextMeta = {
        pageSize,
        lastId: res.data.results[res.data.results.length - 1]?.id || undefined
      }
      commit('SET_AFFILIATED_BUSINESS_META', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAffiliatedBusinessCount({ commit, state }, query) {
    const queries = encodeQueryData(query)
    const url = `${this.$config.apiUrlV2}/affiliated-businesses/count/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async getVariables() {
    try {
      const url = `${this.$config.apiUrlV2}/aff-variables/`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchNewMe({ commit }) {
    try {
      const url = `${this.$config.apiUrlV2}/user/me/`
      const res = await this.$axios.$get(url)
      commit('SET_CURRENT_BALANCE', res.data.aff_balance)
      commit('SET_NEW_ME', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async validate({ commit }, code) {
    try {
      const url = `${this.$config.apiUrlV2}/aff-code/${code}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchPayoutTarget({ commit, dispatch, state }, idBundle) {
    try {
      if (!state.newMe) {
        await dispatch('fetchNewMe')
      }
      if (state.newMe.channel_code) {
        const resChannelName = await dispatch(
          'fetchChannelName',
          state.newMe.channel_code
        )
        state.newMe.channel_name = resChannelName[0]
          ? resChannelName[0].channel_name
          : state.newMe.channel_code
      }
      commit('SET_PAYOUT_TARGET', state.newMe)
      return state.newMe
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async storePayoutTargetData(
    { commit, dispatch },
    { channelCode, accountHolder, accountNumber }
  ) {
    const url = `${this.$config.apiUrlV2}/user/me/payout-info/`
    try {
      const res = await this.$axios.$patch(url, {
        channel_code: channelCode,
        account_holder_name:
          this.$config.duitkuActive === 'enabled' ? undefined : accountHolder,
        account_number: accountNumber,
        payout_provider: this.$config.duitkuActive === 'enabled' ? 'duitku' : undefined
      })
      if (res.data.channel_code) {
        const resChannelName = await dispatch(
          'fetchChannelName',
          res.data.channel_code
        )
        res.data.channel_name = resChannelName[0]
          ? resChannelName[0].channel_name
          : res.data.channel_code
      }
      commit('SET_PAYOUT_TARGET', res.data)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchChannelName(_, idChannel) {
    const url = `${this.$config.apiUrlV2}/payout-channels?channel_code=${idChannel}`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchAllChannel(_, query) {
    const queries = encodeQueryData(query)
    const url = `${this.$config.apiUrlV2}/payout-channels/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async reqOtpWithdraw() {
    const url = `${this.$config.apiUrlV2}/user/me/payout-otp/`
    try {
      const res = await this.$axios.$post(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async withdraw(_, { otp, amount }) {
    const url = `${this.$config.apiUrlV2}/user/me/payout/`
    try {
      const res = await this.$axios.$post(url, {
        otp,
        amount
      })
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async changeAffCode(_, affCode) {
    try {
      const res = await this.$axios.$patch('/user/me/', {
        aff_code: affCode
      })
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async acceptToc() {
    const url = `${this.$config.apiUrlV2}/user/me/accept-terms/`
    try {
      const res = await this.$axios.$post(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async updateFbPixelAffiliate({ commit }, payload) {
    const { pixelId, conversionToken, testEventCode } = payload
    const url = `/user/me/`
    try {
      const res = await this.$axios.$patch(url, {
        aff_pixel_id: pixelId,
        aff_conversion_token: conversionToken,
        aff_test_event_code: testEventCode
      })
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async updateTiktokPixelAffiliate({ commit }, payload) {
    const { pixelId, conversionToken, testEventCode } = payload
    const url = `/user/me/`
    try {
      const res = await this.$axios.$patch(url, {
        aff_tt_pixel_id: pixelId,
        aff_tt_conversion_token: conversionToken,
        aff_tt_test_event_code: testEventCode
      })
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async checkAccountNumber({ commit }, payload) {
    try {
      const { channelCode, accountNumber } = payload
      const url = `${this.$config.apiUrlV2}/business/check-account-number/`
      const res = await this.$axios.$post(url, {
        channel_code: channelCode,
        account_number: accountNumber
      })
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
