import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/whatsappIntegration/index.js'), 'whatsappIntegration/index.js')
  resolveStoreModules(require('../store/warehouse/index.js'), 'warehouse/index.js')
  resolveStoreModules(require('../store/user/index.js'), 'user/index.js')
  resolveStoreModules(require('../store/subscriptionOrder/index.js'), 'subscriptionOrder/index.js')
  resolveStoreModules(require('../store/subscription/index.js'), 'subscription/index.js')
  resolveStoreModules(require('../store/store/index.js'), 'store/index.js')
  resolveStoreModules(require('../store/staff/index.js'), 'staff/index.js')
  resolveStoreModules(require('../store/shipment/index.js'), 'shipment/index.js')
  resolveStoreModules(require('../store/role/index.js'), 'role/index.js')
  resolveStoreModules(require('../store/publicBundle/index.js'), 'publicBundle/index.js')
  resolveStoreModules(require('../store/product/index.js'), 'product/index.js')
  resolveStoreModules(require('../store/permission/index.js'), 'permission/index.js')
  resolveStoreModules(require('../store/payment/index.js'), 'payment/index.js')
  resolveStoreModules(require('../store/page/index.js'), 'page/index.js')
  resolveStoreModules(require('../store/order/index.js'), 'order/index.js')
  resolveStoreModules(require('../store/onboarding/index.js'), 'onboarding/index.js')
  resolveStoreModules(require('../store/notification/index.js'), 'notification/index.js')
  resolveStoreModules(require('../store/newLead/index.js'), 'newLead/index.js')
  resolveStoreModules(require('../store/newForm/index.js'), 'newForm/index.js')
  resolveStoreModules(require('../store/mootaIntegration/index.js'), 'mootaIntegration/index.js')
  resolveStoreModules(require('../store/marketingProgram/index.js'), 'marketingProgram/index.js')
  resolveStoreModules(require('../store/mailketing/index.js'), 'mailketing/index.js')
  resolveStoreModules(require('../store/inventory/index.js'), 'inventory/index.js')
  resolveStoreModules(require('../store/image/index.js'), 'image/index.js')
  resolveStoreModules(require('../store/financialEntity/index.js'), 'financialEntity/index.js')
  resolveStoreModules(require('../store/facebookIntegration/index.js'), 'facebookIntegration/index.js')
  resolveStoreModules(require('../store/domain/index.js'), 'domain/index.js')
  resolveStoreModules(require('../store/discountCode/index.js'), 'discountCode/index.js')
  resolveStoreModules(require('../store/customer/index.js'), 'customer/index.js')
  resolveStoreModules(require('../store/courierAggregator/index.js'), 'courierAggregator/index.js')
  resolveStoreModules(require('../store/courier/index.js'), 'courier/index.js')
  resolveStoreModules(require('../store/common/index.js'), 'common/index.js')
  resolveStoreModules(require('../store/business/index.js'), 'business/index.js')
  resolveStoreModules(require('../store/bundle/index.js'), 'bundle/index.js')
  resolveStoreModules(require('../store/birdsend/index.js'), 'birdsend/index.js')
  resolveStoreModules(require('../store/balanceHistory/index.js'), 'balanceHistory/index.js')
  resolveStoreModules(require('../store/balance/index.js'), 'balance/index.js')
  resolveStoreModules(require('../store/authorization/index.js'), 'authorization/index.js')
  resolveStoreModules(require('../store/affiliate/index.js'), 'affiliate/index.js')
  resolveStoreModules(require('../store/bundle/followUpChat.js'), 'bundle/followUpChat.js')
  resolveStoreModules(require('../store/business/role.js'), 'business/role.js')
  resolveStoreModules(require('../store/common/toast.js'), 'common/toast.js')
  resolveStoreModules(require('../store/customer/address.js'), 'customer/address.js')
  resolveStoreModules(require('../store/customer/contact.js'), 'customer/contact.js')
  resolveStoreModules(require('../store/customer/email.js'), 'customer/email.js')
  resolveStoreModules(require('../store/customer/order.js'), 'customer/order.js')
  resolveStoreModules(require('../store/customer/phone.js'), 'customer/phone.js')
  resolveStoreModules(require('../store/fbAndGtm/fb.js'), 'fbAndGtm/fb.js')
  resolveStoreModules(require('../store/fbAndGtm/gtm.js'), 'fbAndGtm/gtm.js')
  resolveStoreModules(require('../store/fbAndGtm/kwai.js'), 'fbAndGtm/kwai.js')
  resolveStoreModules(require('../store/fbAndGtm/tiktok.js'), 'fbAndGtm/tiktok.js')
  resolveStoreModules(require('../store/inventory/balance.js'), 'inventory/balance.js')
  resolveStoreModules(require('../store/inventory/history.js'), 'inventory/history.js')
  resolveStoreModules(require('../store/location/city.js'), 'location/city.js')
  resolveStoreModules(require('../store/location/province.js'), 'location/province.js')
  resolveStoreModules(require('../store/location/subdistrict.js'), 'location/subdistrict.js')
  resolveStoreModules(require('../store/newForm/editor.js'), 'newForm/editor.js')
  resolveStoreModules(require('../store/order/bundle.js'), 'order/bundle.js')
  resolveStoreModules(require('../store/order/warehouse.js'), 'order/warehouse.js')
  resolveStoreModules(require('../store/page/form.js'), 'page/form.js')
  resolveStoreModules(require('../store/product/followUpChat.js'), 'product/followUpChat.js')
  resolveStoreModules(require('../store/product/variant.js'), 'product/variant.js')
  resolveStoreModules(require('../store/staff/invitationLink.js'), 'staff/invitationLink.js')
  resolveStoreModules(require('../store/staff/joinRequest.js'), 'staff/joinRequest.js')
  resolveStoreModules(require('../store/store/bundle.js'), 'store/bundle.js')
  resolveStoreModules(require('../store/store/channel.js'), 'store/channel.js')
  resolveStoreModules(require('../store/store/facebookCustomAudience.js'), 'store/facebookCustomAudience.js')
  resolveStoreModules(require('../store/store/followUpChat.js'), 'store/followUpChat.js')
  resolveStoreModules(require('../store/store/marketing.js'), 'store/marketing.js')
  resolveStoreModules(require('../store/store/paymentAccount.js'), 'store/paymentAccount.js')
  resolveStoreModules(require('../store/store/paymentMethod.js'), 'store/paymentMethod.js')
  resolveStoreModules(require('../store/store/product.js'), 'store/product.js')
  resolveStoreModules(require('../store/user/role.js'), 'user/role.js')
  resolveStoreModules(require('../store/user/store.js'), 'user/store.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
